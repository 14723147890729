import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { LoginComponent } from './page/login/login.component';
import { ProfileComponent } from './page/profile/profile.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { BtnScrollComponent } from './page/btn-scroll/btn-scroll.component';
import { AdminFichaComponent } from './ficha/admin-ficha/admin-ficha.component';
import { EditFichaComponent } from './ficha/edit-ficha/edit-ficha.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbCollapseModule, NgbDropdownModule, NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { registerLocaleData } from '@angular/common';
import localeCO from '@angular/common/locales/pt';

import { faArrowUp, faBook, faCalendar, faClipboard, faEraser, faEye, faFileExcel, faFilePdf, faRedo, faSearch, faSignOutAlt, faSort, faSortDown, faSortUp, faUser, faUserEdit, faUserPlus, faUsers, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpLoaderFactory } from 'projects/senai-pr/src/app/app.module';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { SearchFichaComponent } from './ficha/search-ficha/search-ficha.component';


registerLocaleData(localeCO, 'pt');


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    LoginComponent,
    ProfileComponent,
    NotFoundComponent,
    BtnScrollComponent,
    AdminFichaComponent,
    EditFichaComponent,
    SearchFichaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgbModalModule,
    NgbCollapseModule,
    NgbDropdownModule,
    NgbTooltipModule,
    FontAwesomeModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    InfiniteScrollModule,
    NgSelectModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    })
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'pt' }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faUserTimes, faUsers, faBook, faClipboard, faUser, faUserEdit, faSignOutAlt, faCalendar, faArrowUp, faSort, faSortUp, faSortDown, faUserEdit, faUserPlus, faFilePdf, faFileExcel, faRedo, faSearch, faEraser, faEye);
  }
}