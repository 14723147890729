import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Ficha } from '../_class/ficha';
import { FichaParams } from '../_class/ficha-params';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class FichaParamsService {

  base = `${environment.endpoint}/v1/rvfichaparams`

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  /**
   * Update data info ficha 
   * @param pk Primary key
   * @param data Data ficha class
   */
  Update(pk: number, data: FichaParams) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.put<FichaParams>(`${this.base}/${pk}`, data, { headers });
  }

  Create(data:FichaParams){
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.post<FichaParams>(`${this.base}`, data, { headers });
  }

}
