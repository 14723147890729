import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Contratista } from '../_class/contratista';
import { Evaluacion } from '../_class/evaluacion';
import { Ficha } from '../_class/ficha';
import { Project } from '../_class/project';
import { ProjectInscription } from '../_class/project-inscription';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TransversalService {

  public base = `${environment.endpoint}/v1/transversal`

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  // Create(data) {
  //   const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
  //   return this.http.post<Project>(this.base, data, { headers });
  // }

  GetFichas(params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Ficha[]>(this.base, { params, headers, observe: 'response' });
  }

  GetEvaluaciones(params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Evaluacion[]>(`${this.base}/evaluaciones`, { params, headers });
  }

  GetContratistas(params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Contratista[]>(`${this.base}/contratistas`, { params, headers });
  }


  GetProject(params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Project[]>(`${this.base}/project`, { params, headers, observe: 'response' });
  }

  GetProjectByPk(id: number, params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Project>(`${this.base}/project/${id}`, { params, headers });
  }


  //#region Inscription

  CreateInscription(data: ProjectInscription) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.post<ProjectInscription>(`${this.base}/inscription`, data, { headers });
  }

  GetInscription(params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<ProjectInscription[]>(`${this.base}/inscription`, { params, headers, observe: 'response' });
  }

  GetInscriptionByPk(id: number, params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<ProjectInscription>(`${this.base}/inscription/${id}`, { params, headers });
  }

  UpdateInscription(id: number, data: ProjectInscription & { setval?: any; merge?: any; resursive?: any; }) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.put<ProjectInscription>(`${this.base}/inscription/${id}`, data, { headers });
  }

  DeleteInscription(id: number) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.delete<ProjectInscription>(`${this.base}/inscription/${id}`, { headers });
  }

  ShareMail(id: number) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<Boolean>(`${this.base}/inscription/${id}/sharemail`, { headers });
  }

  GetToken(Authorization, params?: HttpParams | {
    [param: string]: string | string[];
  }) {
    const headers = { Authorization };
    return this.http.get<ProjectInscription>(`${this.base}/token`, { params, headers });
  }
  //#endregion

  // Update(id: number, data: Project) {
  //   const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
  //   return this.http.put<Project>(`${this.base}/${id}`, data, { headers });
  // }

  // Delete(id: number) {
  //   const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
  //   return this.http.delete<Project>(`${this.base}/${id}`, { headers });
  // }
}
