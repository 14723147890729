import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './page/navbar/navbar.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { EditUserModalComponent } from './page/edit-user-modal/edit-user-modal.component';
import { LoginComponent } from './page/login/login.component';
import { MainLayoutComponent } from './page/main-layout/main-layout.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowUp, faBook, faCalendar, faClipboard, faEraser, faEye, faFileExcel, faFilePdf, faRedo, faSearch, faSignOutAlt, faSort, faSortDown, faSortUp, faUser, faUserEdit, faUserPlus, faUsers, faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbCollapseModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbProgressbarModule, NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { FormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { FichasAdminComponent } from './fichas/fichas-admin/fichas-admin.component';
import { FichasSearchComponent } from './fichas/fichas-search/fichas-search.component';
import { FichaReporteComponent } from './fichas/ficha-reporte/ficha-reporte.component';
import { FichaEditarTrabajadorComponent } from './fichas/ficha-editar-trabajador/ficha-editar-trabajador.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InscriptionAdminComponent } from './inscription/inscription-admin/inscription-admin.component';
import { InscriptionCreateComponent } from './inscription/inscription-create/inscription-create.component';
import { InscriptionSearchComponent } from './inscription/inscription-search/inscription-search.component';
import { BtnScrollComponent } from './page/btn-scroll/btn-scroll.component';
import { NgSelectModule } from '@ng-select/ng-select';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    NotFoundComponent,
    EditUserModalComponent,
    LoginComponent,
    MainLayoutComponent,
    FichasAdminComponent,
    FichasSearchComponent,
    FichaReporteComponent,
    FichaEditarTrabajadorComponent,
    InscriptionAdminComponent,
    InscriptionCreateComponent,
    InscriptionSearchComponent,
    BtnScrollComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbCollapseModule,
    FormsModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    NgbModalModule, 
    NgbProgressbarModule,
    HttpClientModule,
    NgbTooltipModule,
    NgSelectModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      countDuplicates: true
    }),
    InfiniteScrollModule,
    Ng2GoogleChartsModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faUserTimes, faUsers, faBook, faClipboard, faUser, faUserEdit, faSignOutAlt, faCalendar, faArrowUp, faSort, faSortUp, faSortDown, faUserEdit, faUserPlus, faFilePdf, faFileExcel, faRedo, faSearch, faEraser, faEye);
  }
 }
