import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { User } from '../../_class/user';
import { AuthService } from '../../_services/auth.service';
import { UserProfileService } from '../../_services/user-profile.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  isMenuCollapsed = true;
  user = new User();
  username = "";

  modalRef: NgbModalRef;

  constructor(
    private sessionService: AuthService,
    private modal: NgbModal,
    private perfilService: UserProfileService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    if (this.sessionService.isValid) {
      const scope = this.sessionService.getScope();
      this.username = scope.name;
    }
    this.sessionService.onLoginSuccess.subscribe(data => {
      this.username = data.name;
    });
  }

  logout() {
    this.sessionService.Logout();
  }

  open(content) {
    this.modalRef = this.modal.open(content)
  }

  close() {
    this.modalRef.close();
  }

  guardar(user) {
    this.perfilService.Update(user)
      .subscribe((response) => {
        this.toastrService.success("Alterações salvas com sucesso");
        // this.sessionService.setScope({ name: response.body['nombre'] });
        this.close();
      },
        (error) => {
          this.toastrService.error("Nenhuma alteração foi salva.");
          this.close();
        }
      );
  }
}
