export class FichaFilter {
    public ficId?:number;
    public traRut?:string;
    public traMail?:string;
    public traNombre?:string;
    public traPaterno?:string;
    public traMaterno?:string;
    public cargo?:string;
    public sede?:string;
    public curso:string;
    public evaId?:number[];
    public conId?:number;

    constructor(){
        this.ficId = null,
        this.traRut = null,
        this.traMail = null,
        this.traNombre = null,
        this.traPaterno = null,
        this.traMaterno = null,
        this.cargo = null,
        this.sede = null,
        this.curso = null,
        this.evaId = null,
        this.conId = null
    }
}
