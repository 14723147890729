import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Contratista } from '../../_class/contratista';
import { Ficha } from '../../_class/ficha';
import { Trabajador } from '../../_class/trabajador';

@Component({
  selector: 'app-edit-ficha',
  templateUrl: './edit-ficha.component.html',
  styleUrls: ['./edit-ficha.component.scss']
})
export class EditFichaComponent implements OnInit {

  @Input() ficha: Ficha;
  @Input() modal: NgbModalRef;
  @Output() onSave = new EventEmitter();

  newFicha: Ficha;
  contratistaNuevo = false;

  trabajador: Trabajador;
  params = { sede: "", curso: "" };

  constructor() { }

  ngOnInit(): void {
    // this.newFicha = { ...this.ficha };
    this.newFicha = { ...this.ficha };
    this.trabajador = { ...this.ficha.trabajador };
  }

  save() {
    this.onSave.emit({ ficha: this.newFicha, trabajador: this.trabajador });
  }

}
