import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Contratista } from '../../_class/contratista';
import { Evaluacion } from '../../_class/evaluacion';
import { FichaFilter } from '../../_class/filter/ficha-filter';
import * as _ from 'lodash';

@Component({
  selector: 'app-search-ficha',
  templateUrl: './search-ficha.component.html',
  styleUrls: ['./search-ficha.component.scss']
})
export class SearchFichaComponent implements OnInit {

  @Input() evaluaciones: Evaluacion[];
  @Output() search = new EventEmitter();

  fichaFilter: FichaFilter;
  ngOnInit() {
    this.fichaFilter = {
      traRut: "",
      traNombre: "",
      traPaterno: "",
      sede: "",
      curso: "",
      evaId:[]
    };
  }

  submit() {
    this.search.emit(_.pickBy(this.fichaFilter, _.identity));
  }
}