import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { User } from '../_class/user';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  base = `${environment.endpoint}/v1/user/identity`;

  constructor(
    private http: HttpClient,
    private auth: AuthService
  ) { }

  Identity() {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get<User>(this.base, { headers });
  }

  Update(data) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.post(this.base, data, { headers });
  }

  UpdatePass(data: {
    current: string,
    new: string,
    verify: string
  }) {

  }
}
