import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Trabajador } from '../_class/trabajador';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TrabajadorService {

  private base = `${environment.endpoint}/v1/trabajador`;

  constructor(private http: HttpClient, private auth: AuthService) { }

  Update(pk: number, data: Trabajador) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.put<Trabajador>(`${this.base}/${pk}`, data, { headers });
  }

  GetIdentity(data: Trabajador) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.post<Trabajador>(`${this.base}/identity`, data, { headers });
  }

  GetAll(params) {
    const headers = { Authorization: `${this.auth.session.token_type} ${this.auth.session.token}` };
    return this.http.get(`${this.base}`, { headers, params, observe: 'response' });
  }
}
