<div class="modal-header">
    <h4 class="modal-title">{{"FORM-USER.edit-profile"|translate}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="cerrar.emit()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form>
    <div class="modal-body">
        <div class="col-12">
            <label for="username">{{"FORM-USER.username"|translate}}</label>
            <input class="form-control" id="username" name="username" [(ngModel)]="user.username"
                placeholder="{{'FORM-USER.placeholder-username'|translate}}">
        </div>
        <div class="col-12">
            <label for="nombre">{{"FORM-USER.name"|translate}}</label>
            <input class="form-control" id="nombre" name="nombre" [(ngModel)]="user.nombre"
                placeholder="{{'FORM-USER.placeholder-name'|translate}}">
        </div>
        <div class="col-12">
            <label for="cargo">{{"FORM-USER.cargo"|translate}}</label>
            <input class="form-control" id="cargo" name="cargo" [(ngModel)]="user.cargo"
                placeholder="{{'FORM-USER.placeholder-cargo'|translate}}">
        </div>
        <div class="col-12 mt-2 mb-2">
            <input type="checkbox" class="mr-2" id="changePassword" name="changePassword" [(ngModel)]="changePassword">
            <label for="changePassword">{{"FORM-USER.change-password"|translate}}</label>
        </div>
        <div *ngIf="changePassword" [ngbCollapse]="!changePassword">
            <div *ngIf="changePassword" [ngbCollapse]="!changePassword">
                <div class="col-12 mb-2">
                    <label for="password">{{"FORM-USER.password"|translate}}</label>
                    <input type="password" class="form-control" id="new-password" name="new-password"
                        placeholder="{{'FORM-USER.placeholder-password'|translate}}" [(ngModel)]="passwords.pass1">
                </div>
                <div class="col-12 mb-2">
                    <input type="password" class="form-control" id="repeat" name="repeat"
                        placeholder="{{'FORM-USER.placeholder-repeat'|translate}}" [(ngModel)]="passwords.pass2">
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex">
            <button type="submit" class="btn btn-primary justify-content-end"
                (click)="save()">{{"BUTTON.save"|translate}}</button>
        </div>
    </div>
</form>