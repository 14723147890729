<div class="modal-header">
    <h3 class="modal-title">{{"FORM-EDIT-FICHA.title"|translate}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<form #form="ngForm">
    <div class="modal-body">
        <div class="form-row">
            <div class="form-group col-md-4">
                <label for="dni">{{'FORM-EDIT-FICHA.dni'|translate}}</label>
                <input type="text" class="form-control" id="dni" name="dni" disabled="true"
                    [(ngModel)]="trabajador.rut">
            </div>
            <div class="form-group col-md-4">
                <label for="name">{{'FORM-EDIT-FICHA.name'|translate}}</label>
                <input type="text" class="form-control" id="name" name="name" [(ngModel)]="trabajador.nombre">
            </div>
            <div class="form-group col-md-4">
                <label for="name">{{'FORM-EDIT-FICHA.lastName'|translate}}</label>
                <input type="text" class="form-control" id="lastName" name="lastName" [(ngModel)]="trabajador.paterno">
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="d-flex">
            <button [disabled]="form.invalid" class="btn btn-primary justify-content-end" type="submit"
                (click)="save()">{{'BUTTON.save'|translate}}</button>
        </div>
    </div>
</form>