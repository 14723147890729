<ng-template #content let-modal>
    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
        <div class="modal-header">
            <h4 class="modal-title pull-left">{{'LOGIN.auth' | translate}}</h4>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <input name="username" type="text" class="form-control" placeholder="{{'LOGIN.username' | translate}}"
                    [(ngModel)]="model.username" required>
            </div>
            <div class="form-group">
                <input name="password" type="password" class="form-control"
                    placeholder="{{'LOGIN.password' | translate}}" [(ngModel)]="model.password" required>
            </div>
        </div>
        <div class="modal-footer">
            <button type="submit" class="btn btn-primary float-right" [disabled]="!heroForm.form.valid">{{'LOGIN.access'
                | translate}}</button>
        </div>
    </form>
</ng-template>